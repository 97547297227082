<template>
  <v-overlay
    v-model="show"
    persistent
    :width="width"
    :z-index="zIndex"
    :value="overlay"
    @click:outside="show = !show"
  >
    <slot name="body"></slot>
  </v-overlay>
</template>

<script>
export default {
  name: 'ImageOverlay',
  props: {
    visible: {
      type: Boolean,
      default: () => false,
    },
    outside: {
      type: Boolean,
      default: () => false,
    },
    width: {
      type: Number,
      default: () => 600,
    },
  },
  data() {
    return {
      overlay: false,
      zIndex: 10,
    }
  },
  computed: {
    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      },
    },
  },
}
</script>

<style>
</style>
